import React, { useState } from "react";
import { useMount } from "react-use";

import SEO from "../components/MetaSeo";
import MiniHero from "../components/MiniHero";
import Layout from "../components/layout";
// import SearchCourse from "../components/SearchCourse";
// import WhatsappButton from "../components/WhatsappButton";
import Loader from "../components/Loader";
import ImgSVG from "../components/treact/images/server-illustration-2.svg";

const IntegratedForm = () => {
  useMount(() => {
    console.log("useMount");
    if (window.RDStationForms) {
      console.log("window.RDStationForms", window.RDStationForms);
      // Essa linha cria o elemento do formulário e faz o append do conteúdo na div abaixo.
      new window.RDStationForms(
        "formulario-conversao-935613a7d9b50a5b96d1",
        "G-WS1XTZ63CR"
      ).createForm();
    }
  });

  return (
    <div>
      {/* Esta div é necessária para receber o conteúdo do formulário. Substitua os parâmetros com os dados do seu formulário. */}
      <div role="main" id="formulario-conversao-935613a7d9b50a5b96d1"></div>
    </div>
  );
};

const Orcamento = ({ title = "Orçamento", slug = "orçamento" }) => {
  return (
    <Layout>
      <SEO title={title} pathname={slug} />
      <MiniHero name={title} />

      <div className="container mx-auto">
        <section className="text-gray-700 body-font">
          <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg md:w-1/3 w-5/6 mb-10 md:mb-0">
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src={ImgSVG}
              />
            </div>
            <div className="lg:flex-grow md:w-2/3 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <p className="mb-8 leading-relaxed">
                Entre em contato com nosso time para saber mais sobre o seu
                sonho.
              </p>
              <div className="flex justify-center">
                <IntegratedForm />
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* 
      <LeadForm
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        values={{
          startDate: new Date().toISOString().split("T")[0],
          offering: { school: { country: { nameTranslation: "A definir" } } }
        }}
      /> */}
      {/* <WhatsappButton /> */}
    </Layout>
  );
};

export default Orcamento;
